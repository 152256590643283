export default function logUserOut(redirectOrReload = "reload") {
  window.app.$store.commit("userData", null);

  if (redirectOrReload === "redirect") {
    let router;

    import("../router").then(async res => {
      router = res.default;

      if (router.currentRoute.name !== "login") {
        await router.push({ name: "login" });
      }
    });
  } else {
    window.location.reload();
  }
}
