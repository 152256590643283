export default function alert(
  message,
  alertCallback = function() {},
  title = "Alert",
  buttonName = "OK"
) {
  // eslint-disable-line func-names
  if (window.cordova) {
    navigator.notification.alert(message, alertCallback, title, buttonName);
  } else {
    window.alert(`${title}\n\n${message}`); // eslint-disable-line no-alert
    alertCallback();
  }
}
