export default function scrollToFirstError(element) {
  let elementToScroll;

  if (element) {
    elementToScroll = element;
  } else {
    element = document.documentElement; // eslint-disable-line no-param-reassign
    elementToScroll = window;
  }

  const firstError = document.querySelector(".js-form-error");

  if (firstError) {
    const domRect = firstError.getBoundingClientRect();
    elementToScroll.scrollTo({
      top: domRect.top + element.scrollTop - 100,
      left: domRect.left + element.scrollLeft,
      behavior: "smooth"
    });
  }
}
