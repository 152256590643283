export default function confirm(
  message,
  confirmCallback = function() {},
  title = "Confirm",
  buttonLabels = ["OK", "Cancel"]
) {
  // eslint-disable-line func-names
  if (window.cordova) {
    navigator.notification.confirm(message, confirmCallback, title, buttonLabels);
  } else {
    const result = window.confirm(`${title}\n\n${message}`) ? 1 : 2; // eslint-disable-line no-alert
    confirmCallback(result);
  }
}
