import api, { addResponseHandler } from "@virgodev/bazaar/functions/api";

import alert from "./alert";
import confirm from "./confirm";
import dateFromNow from "./date_from_now";
import linebreaksbr from "./linebreaksbr";
import logUserOut from "./log_user_out";
import numberWithCommas from "./number_with_commas";
import scrollToFirstError from "./scroll_to_first_error";

addResponseHandler(401, logUserOut);
addResponseHandler(403, logUserOut);

export default {
  alert,
  api,
  confirm,
  dateFromNow,
  linebreaksbr,
  logUserOut,
  numberWithCommas,
  scrollToFirstError
};
