import Vue from "vue";
import App from "./App.vue";
import globalMethods from "./methods";
import router from "./router";
import store from "./store";
import Sticky from "vue-sticky-directive";
import VTooltip from "v-tooltip";

import "./style.scss";

Vue.config.productionTip = false;

Vue.mixin({
  methods: globalMethods
});

Vue.use(Sticky);
Vue.use(VTooltip);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
